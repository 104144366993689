<template>
  <div>
    <b-alert
      v-if="activeIncident"
      show
      fade
      class="p-1"
      variant="warning"
    >
      {{ $t('AppHelpDesk.activeIncident') }}
    </b-alert>
    <list
      ref="refTicketsList"
      sort-by="TicketId"
      refetch-records-name="tickets"
      key-field="TicketId"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchTickets"
      :sort-dir-desc="false"
    >
      <template #cell(nombreUsuarioReporta)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.usuarioReportaNombreCompleto)"
              variant="light-info"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.usuarioReportaNombreCompleto }}
          </b-link>
          <small class="text-muted">@{{ data.item.nombreUsuarioReporta }}</small>
        </b-media>
      </template>
      <template #cell(nombreUsuarioAsignado)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.usuarioAsignadoNombreCompleto)"
              variant="light-danger"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.usuarioAsignadoNombreCompleto }}
          </b-link>
          <small class="text-muted">{{ data.item.nombreUsuarioAsignado === undefined ? '' : '@' + data.item.nombreUsuarioAsignado }}</small>
        </b-media>
      </template>
      <template #cell(ticketEstatus)="data">
        <b-badge
          pill
          :variant="`light-${getVariant(data.item.ticketEstatus.ticketEstatusId)}`"
          class="text-capitalize"
        >
          {{ data.item.ticketEstatus.nombre }}
        </b-badge>
      </template>

      <template #cell(fechaInicio)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaInicio | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaInicio | formatDate }}
        </b-media>
      </template>

      <template #cell(ticketCategoria)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.ticketCategoria.nombre)"
              variant="light-secondary"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.ticketCategoria.nombre }}
          </b-link>
          <small class="text-muted">{{ data.item.ticketCategoria.ruta }}</small>
        </b-media>
      </template>
    </list>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import ticketService from '@/services/helpDesk.service'
import List from '@/components/List.vue'
import {
  BBadge,
  BAlert,
  BMedia,
  BLink,
  BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    List,
    BBadge,
    BAlert,
    BMedia,
    BLink,
    BAvatar,
  },
  setup() {
    const refTicketsList = ref(null)
    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDateTickets')) {
      dateTimeFormater.push({ key: 'formatDateTickets', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDateTickets'))
    let actions = ref([])

    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
      const index = actions.value.findIndex(e => e.name === 'dateAndHour')
      actions.value[index].value = stateTime.value.value
    }
    const activeIncident = ref(false)
    const {
      fetchTickets,
      deleteTicket,
      fecthActiveIncident,
    } = ticketService()

    const filters = ref([
      {
        type: 'date',
        name: 'fechaDesde',
        label: i18n.t('Components.List.Filters.DateFrom'),
        value: '',
      },
      {
        type: 'date',
        name: 'fechaHasta',
        label: i18n.t('Components.List.Filters.DateTo'),
        value: '',
      },
      {
        type: 'select',
        name: 'nombreUsuarioReporta',
        label: i18n.t('AppHelpDesk.filters.userName'),
        value: '',
        options: [],
        domainName: 'Empleado',
      },
      {
        type: 'select',
        name: 'nombreUsuarioAsignado',
        label: i18n.t('AppHelpDesk.filters.assignedName'),
        value: '',
        options: [],
        domainName: 'Reparadores',
      },
      {
        type: 'select',
        name: 'ticketCategoriaId',
        label: i18n.t('AppHelpDesk.filters.category'),
        value: '',
        options: [],
        domainName: 'CategoriaTicket',
      },
      {
        type: 'select',
        name: 'ticketEstatusId',
        label: i18n.t('AppHelpDesk.filters.status'),
        value: '',
        options: [],
        domainName: 'EstatusTicket',
      },
      {
        type: 'checkbox',
        label: i18n.t('Components.List.Filters.FormatDate'),
        value: 0,
        options: [0, 1],
      },
    ])

    const tableColumns = [
      {
        key: 'ticketId',
        label: i18n.t('AppHelpDesk.columns.ticketId'),
        sortable: true,
        sortKey: 'TicketId',
      },
      {
        key: 'ticketEstatus',
        label: i18n.t('AppHelpDesk.columns.status'),
        sortable: false,
      },
      {
        key: 'nombreUsuarioAsignado',
        label: i18n.t('AppHelpDesk.columns.assigned'),
        sortable: false,
      },
      {
        key: 'nombreUsuarioReporta',
        label: i18n.t('AppHelpDesk.columns.user'),
        sortable: false,
      },
      {
        key: 'ticketCategoria',
        label: i18n.t('AppHelpDesk.columns.category'),
        sortable: false,
      },
      {
        key: 'fechaInicio',
        label: i18n.t('AppHelpDesk.columns.startDate'),
        sortable: true,
        sortKey: 'FechaInicio',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]

    const tableActions = ref([
      {
        name: 'bitacora',
        label: i18n.t('AppHelpDesk.logbook'),
        aclAction: 'read',
        aclResource: 'MesaAyuda',
        routeName: 'apps-tickets-log',
        params: ['ticketId'],
        icon: 'BookOpenIcon',
      },
      {
        name: 'edit',
        label: i18n.t('Lists.Details'),
        aclAction: 'read',
        aclResource: 'MesaAyuda',
        routeName: 'apps-tickets-edit',
        params: ['ticketId'],
        icon: 'CodesandboxIcon',
      },
    ])
    actions = ref([
      {
        label: i18n.t('AppHelpDesk.newTicket'),
        aclAction: 'create',
        aclResource: 'MesaAyuda',
        routeName: 'apps-tickets-add',
        icon: 'PlusCircleIcon',
      },
      {
        name: 'dateAndHour',
        aclAction: 'read',
        aclResource: 'AccionesMejora',
        icon: 'CalendarIcon',
        value: stateTime.value.value,
        click: changeStateTime,
      },
    ])

    fecthActiveIncident(data => {
      activeIncident.value = data
    })

    const getVariant = classification => {
      if (classification === 1) return 'primary'
      if (classification === 2) return 'info'
      if (classification === 3) return 'warning'
      if (classification === 4) return 'secondary'
      return 'success'
    }
    return {
      refTicketsList,
      actions,
      filters,
      tableActions,
      tableColumns,
      stateTime,
      fetchTickets,
      deleteTicket,
      activeIncident,
      getVariant,
      avatarText,
    }
  },
}
</script>
